import React, { lazy, Suspense } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import AuthGuard from "../guards/AuthGuard";
import DashboardLayout from "../layouts";
import { PATHNAME } from "./path";

const Loadable = (Component: any) => (props: any) => {
  return (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );
};
export default function Router() {
  return useRoutes([
    {
      path: PATHNAME.LOGIN,
      element: <Login />,
    },
    {
      path: PATHNAME.REGISTER,
      element: <Register />,
    },
    {
      path: PATHNAME.ROOT,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: "", element: <Overview /> },
        // {
        //   path: PATHNAME.MANAGER_STAFF,
        //   element: <ManagerStaff />,
        // },
        // {
        //   path: PATHNAME.MANAGER_BRANCH,
        //   element: <ManagerBranch />,
        // },
        // {
        //   path: PATHNAME.MANAGER_PERMISSION,
        //   element: <ManagerPermission />,
        // },
        {
          path: PATHNAME.CHANGE_PASSWORD,
          element: <ChangePassword />,
        },
        {
          path: PATHNAME.Collaborators,
          element: <Collaborators />,
        },
        {
          path: PATHNAME.CARD,
          element: <Card />,
        },
        {
          path: PATHNAME.Customer,
          element: <Customer />,
        },
        {
          path: PATHNAME.Partner,
          element: <Partner />,
        },
        {
          path: PATHNAME.ThuChi,
          element: <ThuChi />,
        },
        {
          path: PATHNAME.ConfigCollectExpense,
          element: <ConfigCollectExpense />,
        },
        // {
        //   path: PATHNAME.Pos,
        //   element: <Pos />,
        // },
        {
          path: PATHNAME.Contract,
          element: <Contract />,
        },
        {
          path: `${PATHNAME.Contract}/:scheduleId`,
          element: <ProcessingContract />,
        },
        {
          path: `${PATHNAME.Contract}/push`,
          element: <PushContract />,
        },
        {
          path: `${PATHNAME.Debt}`,
          element: <Debt />,
        },
        {
          path: `${PATHNAME.Report}/turnover`,
          element: <TurnoverReport />,
        },
        {
          path: `${PATHNAME.Report}/cashbook`,
          element: <CashBookReport />,
        },
        {
          path: `${PATHNAME.PostManager}`,
          element: <PostManager />,
        },
        {
          path: PATHNAME.Warning,
          element: <Warning />,
        },
        {
          path: PATHNAME.DebtCompare,
          element: <Compare />,
        },
        {
          path: PATHNAME.ReportAllShop,
          element: <ReportAllShop />,
        },
        {
          path: "/report/debt_aday",
          element: <MoneyBeginDay />,
        },
        {
          path: "/report/reportdaily",
          element: <ReportDaily />,
        },
        {
          path: "/contract/partner",
          element: <ContractPartner />,
        },
        {
          path: "/partner/configfee",
          element: <ConfigFee />,
        },
        {
          path: "/partner/partnershop",
          element: <PartnerShop />,
        },
        {
          path: "/card/expired",
          element: <ExpiredCard />,
        },
        {
          path: "/post/uploadbill",
          element: <Upload />,
        },
        {
          path: "/debt/customer",
          element: <DebtCustomer />,
        },
        {
          path: "/debt/partner",
          element: <DebtPartner />,
        },
        // {
        //   path: "/debt/pos",
        //   element: <DebtPos />,
        // },
        {
          path: "/debt/aff",
          element: <DebtCTV />,
        },
        {
          path: "/report/historycustomerpay",
          element: <CollectionHistoryCustomer />,
        },
        {
          path: "/manager/bankcard",
          element: <ManagerBankCard />,
        },
        {
          path: "/report/cashbookcompany",
          element: <CashbookCompany />,
        },
        {
          path: "/history/logaction",
          element: <LogAction />,
        },
        {
          path: "/contract-cancel",
          element: <ContractCancel />,
        },
        {
          path: "/bao-cao-doanh-thu",
          element: <ReportDoanhThu />,
        },
        {
          path: "/thu-chi-cong-ty",
          element: <ThuChiCongTy />,
        },
        {
          path: "/cau-hinh-phieu-cong-ty",
          element: <CauHinhPhieuCongTy />,
        },
        {
          path: "/bao-cao-cong-no-pos",
          element: <BaoCaoCongNoPos />,
        },
        {
          path: "/bao-cao-cong-no-khach-hang",
          element: <BaoCaoCongNoKhachHang />,
        },
        {
          path: "/bao-cao-thu-chi",
          element: <BaoCaoThuChi />,
        },
        {
          path: "/bao-cao-dong-tien",
          element: <BaoCaoDongTien />,
        },
        {
          path: "/bao-cao-ket-qua-kinh-doanh",
          element: <BaoCaoKetQuaKinhDoanh />,
        },
        {
          path: "/bao-cao-cong-no-doi-tac",
          element: <BaoCaoCongNoDoiTac />,
        },
        {
          path: "/bao-cao-cong-no-ctv",
          element: <BaoCaoCongNoCTV />,
        },
        {
          path: "/expensebad/index",
          element: <ChiPhiNoXau />,
        },
        {
          path: "/schedule-payment",
          element: <Schedule />,
        },
      ],
    },
    { path: "404", element: <NotFound /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
const ContractCancel = Loadable(
  lazy(() => import("../pages/more/ContractCancel"))
);
const LogAction = Loadable(lazy(() => import("../pages/more/LogAction")));
const Login = Loadable(lazy(() => import("../pages/login/Login")));
const Register = Loadable(lazy(() => import("../pages/authencation/Register")));
const NotFound = Loadable(lazy(() => import("../components/NotFound")));
const Warning = Loadable(lazy(() => import("../components/Warning")));
const Overview = Loadable(lazy(() => import("../components/Overview")));
const ManagerStaff = Loadable(lazy(() => import("../pages/manager/Staff")));
const ManagerBranch = Loadable(lazy(() => import("../pages/manager/Branch")));
const ManagerPermission = Loadable(
  lazy(() => import("../pages/manager/Permission"))
);
const ChangePassword = Loadable(
  lazy(() => import("../pages/authencation/ChangePassword"))
);

const Collaborators = Loadable(
  lazy(() => import("../pages/manager/Collaborator"))
);
const Customer = Loadable(lazy(() => import("../pages/manager/Customer")));
const Partner = Loadable(lazy(() => import("../pages/manager/Partner")));
const Card = Loadable(lazy(() => import("../pages/manager/Cards")));
const ThuChi = Loadable(lazy(() => import("../pages/thuchi/index")));
const ConfigCollectExpense = Loadable(
  lazy(() => import("../pages/thuchi/Config"))
);

const Pos = Loadable(lazy(() => import("../pages/pos/index")));
const Contract = Loadable(lazy(() => import("../pages/contract/index")));
const ProcessingContract = Loadable(
  lazy(() => import("../pages/contract/Processing"))
);
const PushContract = Loadable(
  lazy(() => import("../pages/contract/PushContract"))
);
const PostManager = Loadable(
  lazy(() => import("../pages/manager/PostPartner"))
);
const PartnerShop = Loadable(lazy(() => import("../pages/more/PartnerShop")));

const Debt = Loadable(lazy(() => import("../pages/debt/index")));
const ExpiredCard = Loadable(lazy(() => import("../pages/more/ExpriedCard")));
const TurnoverReport = Loadable(lazy(() => import("../pages/report/turnover")));
const CashBookReport = Loadable(lazy(() => import("../pages/report/cashbook")));
const ReportAllShop = Loadable(lazy(() => import("../pages/report/allshop")));
const ReportDaily = Loadable(lazy(() => import("../pages/report/daily")));
const CollectionHistoryCustomer = Loadable(
  lazy(() => import("../pages/report/history_collect_customer"))
);
const Compare = Loadable(lazy(() => import("../pages/debt/Compare")));
const MoneyBeginDay = Loadable(
  lazy(() => import("../pages/more/MoneyBeginDay"))
);

const ContractPartner = Loadable(
  lazy(() => import("../pages/contract/Partner"))
);
const ConfigFee = Loadable(lazy(() => import("../pages/manager/ConfigFee")));
const Upload = Loadable(lazy(() => import("../pages/upload/index")));
const DebtCustomer = Loadable(lazy(() => import("../pages/debt/Customer")));
const DebtPartner = Loadable(lazy(() => import("../pages/debt/Partner")));
const DebtPos = Loadable(lazy(() => import("../pages/debt/Pos")));
const DebtCTV = Loadable(lazy(() => import("../pages/debt/Ctv")));
const ManagerBankCard = Loadable(
  lazy(() => import("../pages/manager/BankCard"))
);
const CashbookCompany = Loadable(
  lazy(() => import("../pages/report/cashbookcompany"))
);
const ReportDoanhThu = Loadable(
  lazy(() => import("../pages/bao-cao-doanh-thu/index"))
);
const ThuChiCongTy = Loadable(lazy(() => import("../pages/company/thu-chi")));
const CauHinhPhieuCongTy = Loadable(
  lazy(() => import("../pages/company/cau-hinh-phieu"))
);
const BaoCaoThuChi = Loadable(
  lazy(() => import("../pages/company/bao-cao-thu-chi"))
);
const BaoCaoCongNoPos = Loadable(
  lazy(() => import("../pages/report/cong-no-pos"))
);
const BaoCaoCongNoKhachHang = Loadable(
  lazy(() => import("../pages/report/cong-no-khach-hang"))
);
const BaoCaoDongTien = Loadable(
  lazy(() => import("../pages/company/bao-cao-dong-tien"))
);
const BaoCaoKetQuaKinhDoanh = Loadable(
  lazy(() => import("../pages/company/bao-cao-ket-qua-kinh-doanh"))
);
const BaoCaoCongNoDoiTac = Loadable(
  lazy(() => import("../pages/company/bao-cao-cong-no-doi-tac"))
);
const BaoCaoCongNoCTV = Loadable(
  lazy(() => import("../pages/company/bao-cao-cong-no-ctv"))
);
const ChiPhiNoXau = Loadable(lazy(() => import("../pages/chi-phi-no-xau")));
const Schedule = Loadable(lazy(() => import("../pages/schedule/index")));
